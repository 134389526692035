import './App.css';
import beta from '../src/images/beta.png';
import phoneIcon from '../src/images/phoneIcon.png';
import greenPhoneIcon from '../src/images/greenPhoneIcon.png';
import applogo from '../src/images/applogo.png';
import bottombarbg from '../src/images/bottombarbg.png';
import mobBottomBar from '../src/images/mobBottomBar.png';
import bgImage from '../src/images/bgImage.png';
import clipBoard from '../src/images/clipBoard.png';
import { useEffect, useRef, useState } from 'react';
import { Box, Card, CardContent, createTheme, Grid, List, ListItem, Typography, useMediaQuery, Tooltip, Button } from '@mui/material';
import { sections } from './data';
import { RingLoader } from 'react-spinners';
import useDetectKeyboardOpen from "use-detect-keyboard-open";

function App() {

  const iframeRef = useRef(null);
  const theme = createTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [currentSlide, setCurrentSlide] = useState(0);
  const cardRefs = useRef([]);
  const [tooltipIndex, setTooltipIndex] = useState(null);
  const primary_color = '#00A651'
  const [selectedSection, setSelectedSection] = useState(0);
  const isKeyboardOpen = useDetectKeyboardOpen();

  const scrollToSection = () => {
    document.getElementById('element')?.scrollIntoView({ behavior: "smooth", block: "start" });
  };
  const unsecuredCopyToClipboard = (text) => { const textArea = document.createElement("textarea"); textArea.value = text; document.body.appendChild(textArea); textArea.focus(); textArea.select(); try { document.execCommand('copy') } catch (err) { console.error('Unable to copy to clipboard', err) } document.body.removeChild(textArea) };

  const handleCopyClick = (textToCopy, index) => {

    if (window.isSecureContext && navigator.clipboard) {
      navigator.clipboard.writeText(textToCopy).then(() => {
        setTooltipIndex(index);
        setTimeout(() => {
          setTooltipIndex(null);
        }, 1500); // Hide tooltip after 1.5 seconds
      });
    } else {
      unsecuredCopyToClipboard(textToCopy);
    }
  };
  const handleScroll = (event) => {
    if (isMobile) {
      const scrollPosition = event.target.scrollLeft;
      const cardWidth = event.target.children[0].offsetWidth;
      const newSlide = Math.round(scrollPosition / cardWidth);
      setCurrentSlide(newSlide);
    }
  };
  const handleSectionClick = (index) => {
    setSelectedSection(index);
  };

  const handleDotClick = (index) => {

    if (cardRefs.current[index]) {
      cardRefs.current[index].scrollIntoView({ behavior: 'smooth' });
    }

  };


  useEffect(() => {
    if (isKeyboardOpen === false) {
      scrollToSection()
    }
  }, [isKeyboardOpen])

  return (
    <Box sx={{ backgroundSize: 'cover', backgroundImage: { xs: '', sm: `url(${bgImage})` }, backgroundRepeat: 'no-repeat', height: '100vh', width: '100vw', backgroundPosition: 'center', position: 'absolute', zIndex: 1, overflowX: 'hidden' }}>
      {
        isMobile &&
        <Box boxShadow={'0px 0px 20px rgba(0, 0, 0, 0.2)'} position={'absolute'} zIndex={2} bgcolor={"#ffffff"} display={'flex'} flexDirection={'row'} alignItems={'center'} justifyContent={'space-around'} sx={{ height: '10vh', position: 'fixed', width: '100vw' }}>
          <img width={140} height={70} src={applogo} alt='applogo' style={{ marginRight: '20px' }} />
          <Button href='tel: 7400199990' type='submit' sx={{ height: { sm: 55, xs: 42 }, width: 150, bgcolor: primary_color, borderRadius: 25, color: '#ffffff', fontSize: { xs: 10, sm: 14 }, fontWeight: 'bold', boxShadow: '0px 0px 20px rgba(97, 157, 106, 0.4)' }}><img src={phoneIcon} alt="phoneIcon" width={15} height={15} style={{ margin: 5 }} />Talk to a Human</Button>
        </Box>
      }
      <div className='container'>
        {!isMobile && <img width={140} height={58} src={applogo} alt='applogo' style={{ marginRight: '20px' }} />}
        <h2>tripXOXO AI <img src={beta} alt="beta" width={60} height={35} style={{ marginLeft: 5, marginBottom: -10 }} /></h2>
      </div>
      <div id='element' />
      <p style={{ wordSpacing: 2, lineHeight: 1.5, fontSize: 14, textAlign: 'center', maxWidth: isMobile ? '80vw' : '100vw', margin: '0 auto' }}>Looking for travel itineraries or things to do recommendations for your customers? {!isMobile && <br />} Talk to tripXOXO AI & get the best suggestions and recommendations!</p><br /><br />
      <Box display={"flex"} justifyContent={'center'} sx={{ height: '62vh', width: { xs: '100vw', sm: '100vw' }, position: 'relative' }}>
        <RingLoader loading={true} color={primary_color} cssOverride={{ top: '30%' }} />
        <Box sx={{ height: '100%', width: { xs: '100vw', sm: '60vw' }, position: 'absolute', display: 'flex', justifyContent: 'center' }}>
          <iframe
            ref={iframeRef}
            src={process.env.REACT_APP_EMBEDD_URL}
            width="100%"
            height="100%"
            allow="clipboard-write *"
            style={{ border: 'none', borderRadius: '25px' }}
            title="Zapier Chatbot"
          />
        </Box>
      </Box>
      <Box sx={{ overflowX: 'auto', display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', background: '#F0F6F1', borderTopRightRadius: { sm: 350, xs: 60 }, borderTopLeftRadius: { sm: 350, xs: 60 }, "::-webkit-scrollbar": { display: 'none' } }}>
        <br /><Typography fontWeight={'bold'} fontSize={{ sm: 28, xs: 18 }} color='#4f4f4f'>Suggested Prompts</Typography><br/>
        <Typography style={{ height: '1vh', wordSpacing: 2, lineHeight: 1.2, fontSize: 14, textAlign: 'center', maxWidth: isMobile ? '80vw' : '100vw' }}>{sections[selectedSection]['desciption']}</Typography><br />{isMobile && <><br /><br /></>}
        <List sx={{ paddingLeft: '20px', paddingRight: '20px', display: 'flex', flexDirection: 'row', overflow: 'scroll', width: isMobile ? 'calc(100vw - 30px)' : 'inherit', "::-webkit-scrollbar": { display: 'none' } }}>
          {sections.map((data, index) => (
            <ListItem key={index} onClick={() => handleSectionClick(index)} button="true" sx={{ cursor: 'pointer', borderRadius: '30px', filter: index === selectedSection ? 'drop-shadow(0px 4px 8px rgba(97, 157, 106, 0.4))' : 'drop-shadow(0px 2px 4px rgba(0, 0, 0, 0.1))', backgroundColor: index === selectedSection ? primary_color : '#ffffff', margin: 1, '&:hover': { backgroundColor: index === selectedSection ? primary_color : '#ffffff', filter: index === selectedSection ? 'drop-shadow(0px 4px 8px rgba(97, 157, 106, 0.4))' : 'drop-shadow(0px 4px 8px rgba(0, 0, 0, 0.1))' }, height: 44, transition: 'filter 0.3s ease-in-out' }} gap={3}>
              <Typography noWrap fontWeight={600} fontSize={14} color={index === selectedSection ? '#ffffff' : '#4f4f4f'}>{data.title}</Typography>
            </ListItem>
          ))}
        </List>

        <Box display={'flex'} flexDirection={'column'} sx={{ width: { xs: '85vw', sm: '63vw' } }}>
          <Typography fontWeight={'bold'} fontSize={{ sm: 28, xs: 18 }} color='#4f4f4f'>{sections[selectedSection]['title']}</Typography>
          <Typography sx={{ wordSpacing: 2, lineHeight: 1.5, fontSize: 14, textAlign: 'start', maxWidth: '100vw' }}>
            Use the below prompts to get travel ideas generated tailored for your customers
          </Typography>
        </Box>
        {!isMobile && <Button href='tel: 7400199990' type='submit' sx={{ position: 'fixed', bottom: 20, right: 20, height: 55, width: 200, bgcolor: primary_color, borderRadius: 25, borderTopLeftRadius: 0, color: '#ffffff', fontSize: 14, fontWeight: 'bold', boxShadow: '0px 0px 20px rgba(97, 157, 106, 0.4)', zIndex: 1000 }}> <img src={phoneIcon} alt="phoneIcon" width={20} height={20} style={{ marginRight: 7 }} /> Talk to a Human</Button>}
        <br />
        <Grid gap={{ sm: 5, xs: 0 }} onScroll={handleScroll} container spacing={{ xs: 2, md: 3 }} columns={{ xs: 4, sm: 8, md: 12 }} sx={{ paddingRight: isMobile ? '6%' : 0, flexWrap: isMobile ? 'nowrap' : 'wrap', overflow: 'scroll', "::-webkit-scrollbar": { display: 'none' }, width: 'auto', display: 'flex', justifyContent: { sm: 'center', xs: 'start' }, paddingTop: 5 }}>
          {sections[selectedSection]['data'].map((card, index) => (
            <Card ref={(el) => (cardRefs.current[index] = el)} sx={{ flexGrow: 1, flexShrink: 1, flexBasis: 'auto', boxShadow: '0px 0px 20px rgba(97, 157, 106, 0.1)', mb: 1, marginLeft: { xs: '10%', sm: 0 }, borderRadius: 5, minWidth: { sm: 'auto', md: 'auto', xs: 230 }, maxWidth: { sm: '20%', xs: '80%' }, minHeight: 'auto', maxHeight: 'auto', height: 'auto', }}>
              <CardContent  sx={{ display: 'flex', flexDirection: 'row', marginTop: 1, gap: 0.5 }}>
                <Typography gutterBottom sx={{ fontSize: 14 }}>
                  {card}
                </Typography>
                <Tooltip title="Text copied!" open={tooltipIndex === index} disableHoverListener disableFocusListener disableTouchListener placement="top" >
                  <img style={{ cursor: 'pointer' }} src={clipBoard} alt='clipBoard' width={19} height={23} onClick={() => handleCopyClick(card, index)} />
                </Tooltip>
              </CardContent>
            </Card>
          ))}
        </Grid>
        {isMobile && (
          <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}>
            {sections[selectedSection]['data'].map((_, index) => (
              <Box
                key={index}
                sx={{ width: 30, height: 5, opacity: 1, borderRadius: 5, backgroundColor: index === currentSlide ? primary_color : '#D9D9D9', mx: 0.5 }}
                onClick={() => handleDotClick(index)}
              />
            ))}
          </Box>
        )}<br />
      </Box>
      <Box position="relative" width="100vw">
        <img alt='bottombarbg' width='100%' height={isMobile ? '200px' : '100%'} src={isMobile ? mobBottomBar : bottombarbg} style={{ display: 'block' }} />
        <Typography
          sx={{ position: 'absolute', top: '30%', left: '50%', transform: 'translate(-50%, -50%)', textAlign: 'center', width: '100%', color: 'white', fontWeight: 'bold', fontSize: { xs: '1rem', sm: '2rem' }, }}>
          Want to talk to a Human?
        </Typography>
        <Button href='tel: 7400199990' type='submit' sx={{ top: '60%', left: '50%', transform: 'translate(-50%, -50%)', position: 'absolute', height: { sm: 55, xs: 42 }, width: isMobile ? 200 : 250, bgcolor: '#FAA633', borderRadius: 25, borderTopLeftRadius: 0, color: '#ffffff', fontSize: isMobile ? 10 : 14, fontWeight: 'bold', boxShadow: '0px 0px 20px rgba(97, 157, 106, 0.7)' }}><img src={greenPhoneIcon} alt="phoneIcon" width={20} height={20} style={{ margin: 7 }} />Call us on 7400199990</Button>
      </Box>
    </Box >
  );
}

export default App;